

@mixin fadeIn($seconds) {
  -webkit-animation: fadein $seconds; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein $seconds; /* Firefox < 16 */
  -ms-animation: fadein $seconds; /* Internet Explorer */
  -o-animation: fadein $seconds; /* Opera < 12.1 */
  animation: fadein $seconds;
}

/*
 * FadeIn Animation Keyframes
 */

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}