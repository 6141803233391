
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Font Awesome
@import "resources/assets/sass/vendor/font-awesome/font-awesome";

// Variables
@import "mixins";
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Fullcalendar
@import "bower_components/fullcalendar/dist/fullcalendar";

// Angular-Moment-Picker
@import "node_modules/angular-moment-picker/dist/angular-moment-picker.min";



$full-height: 100vh;
$full-width: 100vw;
$body-margin: 10px;
$content-max-height: calc(#{$full-height} - (2 * #{$body-margin}));
$content-max-width: calc(#{$full-width} - (2 * #{$body-margin}));


html {
  overflow-y: scroll;
  position: relative;
  min-height: 100%;
}


body {
  margin: $body-margin;
  background-color: #eee;

  position: relative;
  overflow-x: hidden;

  padding-right: 0 !important
}

// Disable Outline
button,
button:active,
button:focus,
button:hover,
.btn,
.btn:active,
.btn:focus,
.btn:hover{
  outline:none !important;
}

*:focus {
  outline: none;
}

.main-view {
  @include fadeIn(1s);
  height: $content-max-height;

  .state-main {
    @include fadeIn(1s);
    height: $content-max-height;
    padding: 0;

    .state-main-board {
      height: $content-max-height;

      .state-main-calendar {
        height: $content-max-height;

        .calendar-agenda {
          height: calc(#{$content-max-height} - 260px);

          .fc-toolbar {
            height: 32px !important;
          }

          .fc-view-container {
            height: calc(#{$content-max-height} - 32px - 1em - 260px) !important;
            overflow-y: scroll;
            overflow-x: hidden;
          }

          h2 {
            font-size: 20px;
            padding-top: 4px;
          }

        }

        .calendar-datepicker {
          height: 240px;
          margin-top: 20px;
          right: 15px;

          .uib-daypicker {

            table {
              width: 100%;


            }

          }

          .btn, .btn-default {
            span {
              color: #000000;
            }

          }

          .weekend {
            background-color: #efc1c1 !important;
            color: #850E0B !important;

            button {
              background-color: #efc1c1 !important;
              color: #850E0B !important;

              span {
                color: #850E0B !important;
              }
            }

          }

          .not-this-month {
            background-color: #d2d1d1 !important;

            button {
              background-color: #d2d1d1 !important;
            }
          }

        }
      }

      .state-main-dash {
        height: $content-max-height;

      }

    }

  }

  .state-patients {
    @include fadeIn(1s);
    height: $content-max-height;
    padding: 0;


    .patient-search {
      @include fadeIn(1s);

      height: calc(#{$content-max-height} - 300px) !important;

      .patients-result-list {
        @include fadeIn(1s);
        height: 100%;
        overflow-y: scroll;
        margin-bottom: 40px;

        .patient-list {


          li {

            .active {
              background-color: #216a94;
              color: #f5f8fa;
            }

            &:hover {
              background-color: #636b6f;
              color: #f5f8fa;
            }
          }
        }

      }

    }

    .patient-overview {
      @include fadeIn(1s);
      padding-left: 30px;

      height: calc(#{$content-max-height} - 100px) !important;

      .row {
        margin-right: 0;
      }

      dl {

        dt, dd {
          line-height: 1.9;
        }

        dt {
          width: 100px;
          text-align: left;

        }
        dd {
          margin-left: 120px;
        }

      }

      .therapy-list {

      }

      .therapy-overview {

      }

    }

  }

}


/*
 *  Auth
 */
.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;

  .form-signin-heading, .checkbox {
    margin-bottom: 10px;
  }

  .checkbox {
    font-weight: normal;
  }

  .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;

    &:focus {
      z-index: 2;
    }

  }

  input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

}






$navbar-highlight-colors: (
  1: (#ec1b5a, #eee),
  2: (#79aefe, #eee),
  3: (#314190, #eee),
  4: (#279636, #eee),
  5: (#7d5d81, #eee),
  6: (#ead24c, #222),
  7: (#2d2366, #eee),
  8: (#35acdf, #eee),
);

#sidebar-wrapper {
  z-index: 1000;
  left: 220px;
  width: 0;
  height: 100%;
  margin-left: -220px;
  background: #1a1a1a;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;


  &.navbar-opened {
    margin-left: 0;
  }

  .sidebar-brand {
    position: absolute;
    top: 0;
    width: 220px;
    margin: 0 0 0 -220px;
    padding: 0;
    list-style: none;

    height: 65px;
    font-size: 20px;
    line-height: 44px;

    color: #fff;
    background-color: #1a1a1a;

    a {
      display: block;
      color: #ddd;
      text-decoration: none;
      padding: 10px 15px;

      &:hover,
      &:active,
      &:focus{
        color: #fff;
        text-decoration: none;
        background-color: transparent;
      }
    }

  }

  /*-------------------------------*/
  /*     Sidebar nav styles        */
  /*-------------------------------*/

  .sidebar-nav {
    position: absolute;
    top: 65px;
    width: 220px;
    margin: 0 0 0 -220px;
    padding: 0;
    list-style: none;
    background-color: #1c1c1c;

    .regular {
      position: relative;
      line-height: 20px;
      width: 100%;
      background-color: #1c1c1c;
      height: 40px;

      a {
        display: block;
        color: #ddd;
        text-decoration: none;
        padding: 10px 15px 10px 40px;
        position: absolute;
        right: 0;
        left: 0;

        &:hover,
        &:active,
        &:focus{
          text-decoration: none;
          background-color: transparent;
        }
      }

      .navbar-icon {
        position: absolute;
        top: 12px;
        left: 15px;
        color: #ddd;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 3px;
        background-color: #1c1c1c;
        -webkit-transition: width .2s ease-in;
        -moz-transition:  width .2s ease-in;
        -ms-transition:  width .2s ease-in;
        transition: width .2s ease-in;
      }

      &:hover {
        &:before {
          width: 100%;
          -webkit-transition: width .2s ease-in;
          -moz-transition:  width .2s ease-in;
          -ms-transition:  width .2s ease-in;
          transition: width .2s ease-in;

        }
      }

      &.open {

        background-color: #1c1c1c;

        a {
          &:hover,
          &:active,
          &:focus{
            text-decoration: none;
            background-color: transparent;
          }
        }

        &:hover {
          &:before {
            width: 100%;
            -webkit-transition: width .2s ease-in;
            -moz-transition:  width .2s ease-in;
            -ms-transition:  width .2s ease-in;
            transition: width .2s ease-in;
          }
        }
      }
    }

    @each $number, $back in $navbar-highlight-colors {
      .regular:nth-child(#{$number}) {
        &:hover {
          & > a, & > span {
            color: nth($back, 2);
          }
        }
        &:before {
          background-color: nth($back, 1);
        }
      }
    }

    .divider, .dropdown-menu .divider {
      position: relative;
      width: 100%;
      background-color: #1c1c1c;
      margin: 5px 0;
      border-top: 2px solid #2f2f2f;
    }

    .dropdown-menu {
      position: relative;
      width: 100%;
      padding: 0;
      margin: 0;
      border-radius: 0;
      border: none;
      background-color: #222;
      box-shadow: none;


    }

  }

  /*-----------------------*/
  /*       Hamburger       */
  /*-----------------------*/

  .hamburger {
    position: fixed;
    top: 20px;
    z-index: 999;
    display: block;
    width: 32px;
    height: 32px;
    margin-left: 15px;
    background: transparent;
    border: none;

    &:hover,
    &:focus,
    &:active
    {
      outline: none;
    }

    &.is-closed {

      .hamb-top,
      .hamb-middle,
      .hamb-bottom {
        position: absolute;
        left: 0;
        height: 4px;
        width: 100%;
        background-color: #1a1a1a;
      }

      .hamb-top {
        top: 5px;
        -webkit-transition: all .35s ease-in-out;
      }
      .hamb-middle {
        top: 50%;
        margin-top: -2px;
      }
      .hamb-bottom {
        bottom: 5px;
        -webkit-transition: all .35s ease-in-out;
      }


      &:hover{

        .hamb-top {
          top: 0;
          -webkit-transition: all .35s ease-in-out;
        }
        .hamb-bottom {
          bottom: 0;
          -webkit-transition: all .35s ease-in-out;
        }

        &:before {
          opacity: 1;
          display: block;
          -webkit-transform: translate3d(-100px,0,0);
          -webkit-transition: all .35s ease-in-out;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 100px;
        font-size: 14px;
        color: #fff;
        line-height: 32px;
        text-align: center;
        opacity: 0;
        -webkit-transform: translate3d(0,0,0);
        -webkit-transition: all .35s ease-in-out;
      }
    }

    &.is-open {

      .hamb-top,
      .hamb-middle,
      .hamb-bottom {
        position: absolute;
        left: 0;
        height: 4px;
        width: 100%;
        background-color: #1a1a1a;
      }

      .hamb-top,
      .hamb-bottom {
        top: 50%;
        margin-top: -2px;
      }

      .hamb-top {
        -webkit-transform: rotate(45deg);
        -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
      }

      .hamb-middle {
        display: none;
      }

      .hamb-bottom {
        -webkit-transform: rotate(-45deg);
        -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
      }

      &:hover:before {
        opacity: 1;
        display: block;
        -webkit-transform: translate3d(-100px,0,0);
        -webkit-transition: all .35s ease-in-out;
      }

      &:before {
        content: '';
        display: block;
        width: 100px;
        font-size: 14px;
        color: #fff;
        line-height: 32px;
        text-align: center;
        opacity: 0;
        -webkit-transform: translate3d(0,0,0);
        -webkit-transition: all .35s ease-in-out;
      }
    }
  }

}






